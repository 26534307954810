@use 'colors';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import url('https://unpkg.com/leaflet@1.6.0/dist/leaflet.css');
@import '../../node_modules/leaflet-geosearch/dist/geosearch.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.App-header {
  background-color: colors.get(primary, green-100);
  color: colors.get(neutrals, grey-800);
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding-top: 10px;
  padding-left: 20px;
}

.app-subtitle {
  font-weight: normal;
}

.App-link {
  color: #61dafb;
}

.leaflet-container {
  width: 100%;
  height: 600px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10em;
}

.spinner-border {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    vertical-align: -0.125em;
    border: 0.5em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
}

@media (max-width:  1200px) {
  .navbar {
    justify-content: flex-end;
  }
  .search-location {
    max-width: 6rem;
  }
}

.nav-link {
  margin-left: 2rem;
}

.nav-link.active {
  font-weight: 700;
  border-bottom: .25rem solid;
}

.text-page {
  max-width: 1000px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.footer {
  font-size: small;
}

.btn-toolbar {
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: space-around;
}

.btn-group {
  margin-right: 10px;
}

.btn-primary {
  background-color: colors.get(primary, green-500);
  border-color: colors.get(primary, green-600);
}

.btn-primary:hover {
  background-color: colors.get(primary, green-600);
  border-color: colors.get(primary, green-900);
}

.control-button {
  border: none;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: colors.get(neutrals, grey-800);
  background-color: colors.get(primary, green-050);
}

.leaflet-touch .leaflet-bar {
  background: none;
  border: none;
}

.leaflet-control-zoom-out,
.leaflet-control-zoom-in,
.leaflet-geosearch-button {
  @extend .control-button;
}

.leaflet-bar a {
  @extend .control-button;
}

.leaflet-bar a:hover,
.easy-button-button:hover {
  color: colors.get(neutrals, grey-900);
  background-color: colors.get(primary, green-400);
}

.easy-button-button {
  @extend .control-button;
  border-radius: 4px;
}

.geosearch a {
  border-radius: 4px !important;
}
